import React from 'react';

import Story from './components/story';
import { Link } from '../../components';

export default function Avios() {
  /* eslint-disable max-len */
  const props = {
    name: 'Avios',
    title:
      'Why Avios chose Fidel API to cut friction and build a better global rewards programme',
    seoTitle:
      'Why Avios chose Fidel API to cut friction and build a better global rewards programme',
    subtitle: 'With the Digital Product Manager at Avios',
    heroImgAlt:
      'Wing of a large airplane, with clouds and the sun in the background',
    keyFigures: {
      Website: <Link to="https://www.avios.com">avios.com</Link>,
      industry: 'Travel Rewards',
      customers: 'Over 7.7M',
      'Products used': 'Transactions',
    },
    companyElement: (
      <p>
        Avios is the global currency of travel. It turns everyday spending into
        travel and leisure rewards for almost 8 million customers around the
        world. If you’ve ever shopped at a Tesco, filled your car up at Shell,
        stayed at a Hilton Hotel, or flown with Aer Lingus, you’ll be familiar
        with Avios.
      </p>
    ),
    challengeTitle: 'Cutting friction for global customers',
    challengeElement: (
      <>
        <p>
          In late 2017, with their sights set on further global expansion, Avios
          noticed an issue. While UK customers could link their payment cards
          and earn Avios points for online purchases, they couldn’t earn points
          on in-store purchases. The points they did earn could take months to
          be rewarded – and for customers outside the UK, things were even more
          complicated. Those customers had to log into their Avios accounts,
          search for offers manually, and be redirected to the retailer’s
          website before they could complete their purchase. The additional
          steps were adding too much friction, so Avios looked to streamline.
        </p>
        <p>
          The lack of functionality outside the UK was also causing another
          problem. Irish airline Aer Lingus was set to join the Avios programme
          imminently. They wanted to increase loyalty by offering customers
          Avios points on Aer Lingus flights, as well as on everyday purchases
          at participating retailers. But Reward, Avios’ existing card-linking
          provider, was UK-based only – meaning Aer Lingus wouldn’t be able to
          use their product.
        </p>
        <p>
          And there was a third challenge. Operating a global reward programme
          meant Avios would have to comply with different data handling
          regulations in each geography. To prevent additional complexity and
          remove the compliance burden, Avios looked for a way to avoid handling
          and storing customer payment data.
        </p>
      </>
    ),
    highlight:
      '“Working with Fidel API has been and continues to be fast and effortless! They understand our key challenges and aspirations, and they are continuously finding innovative and creative ways to allow our customers and partners to get the most from Avios.”',
    highlightImgAlt: 'A child looking out of a plane’s window',
    solutionTitle: 'Building a rewarding customer journey beyond borders',
    solutionElement: (
      <>
        <p>
          Avios’ Digital Product Manager was tasked with addressing these
          challenges. He began by looking for a partner that would allow Avios
          customers outside the UK to link their payment card and earn points.
          Fidel API’s global partnerships with the three major card schemes
          (Visa
          <sup>©</sup>, Mastercard<sup>©</sup> and Amex<sup>©</sup>) was
          crucial. It meant that international customers could easily link their
          payment cards to Avios, without Avios needing to worry about PCI
          compliance or regulatory risks.
        </p>
        <p>
          Avios used Fidel’s API to integrate card-linking with their internal
          systems in just 4 weeks – in time for Aer Lingus’ onboarding. Aer
          Lingus hadn’t yet cemented any partnerships with local retailers, so
          the Fidel API Partnership team worked with them to identify and
          partner with local retailers, including Grafton Barbers, Hotel Merrion
          and Oliver Bonas. In a matter of weeks, Aer Lingus was up and running,
          with customers able to easily link their cards and earn points for
          flights and purchases.
        </p>
        <p>
          Now when a customer signs up to Avios, they’re prompted to link their
          card using Fidel API’s SDK. It only takes a couple of clicks. Then,
          they’re shown offers from local retailers where they can spend to earn
          Avios points. There’s no need to activate or claim an offer – when the
          customer makes a purchase with their linked payment card, they’re
          automatically rewarded with Avios points. Those points can then be
          exchanged for flights with airlines like Aer Lingus or Iberia.
        </p>
        <p>
          In the year since launching, Aer Lingus has grown its customer base of
          card-linked members twelve-fold — an increase of over one thousand
          percent since the launch of their card-linked program in July 2018.
        </p>
      </>
    ),
  };

  /* eslint-enable */
  return <Story {...props} />;
}
